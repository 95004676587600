import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Last week, I had the honor of sitting on a panel about the future of cash with people like Hikmet Ersek (the CEO of Western Union) and Larry Summers.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "416px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYUlEQVQozwFWA6n8AEcpG1RLIWBqJ1ROIU4+HUoxHkcsG0kuG0gwHFE7IVpGJUg3HkM0H2RQL2FPLmBNLmBMLWFKLGBGKl5EKQBIKBxeZSR/tUKCslZ5o0ZvizlndC5bVyRTQR9NMRtMKxxJKhpJLxxaRCVcSCdCNBxeSShkTCtfRSZeQiYASSgeYWgmfq8+g7NbhbtZiMBfib9ghbpTfqpAdZQ4aXkpYlwlWUMgVDQeVDAdSysaWDghX0EjQywZWTggAE4sHmZwJ4CuRX6nSn+oSn+nS4K2QoW3Soa6SYrCV4vCV4i9VYCsRXmWPW98MmRdKFlFH1c3HlQvHFUwHABVLyJpciSj2Kes2eOiycmgx7WYzYaSxWiJvUyEuD2CtjmEuUeGu06Kv1iJvlyAszh7ojNzjDRhWCJRLBwAWTAjcnwui71Pkr9tnMyQpNKzrNnMtunhturlseXaqN61mMmCj79pi7xXhrVGfKwie6YoeqExbHErVS4dAF4zJnmFLJfHa5vDi5a9d4+tdo2rapCya5G7ZJrOc6TYlqXUqKfVwqjUxbDe0qrYwaDMooq6WG1xJFkuIQBkNip+ii2o2pG14OS85um55Oq76Oa/6+e348yw46yl14eXyVyWyF6Vxl2Xx2WZyHahzpySwl5zeyFfMSQAaDotgo8wrN2VueHfs9zKtN3KuOTUwPDiw/PrxPP2wfDotd/NttzestjNsdzDrdmyp9CdlMNReoMlZDQoAG4/MoeSM7Ddmrvg3bje0Lvi08Hq4Mn29MXy68Tw6MHt27ffxrbb1bbb0b3k5L7m7cLm/qTPgX+HIms6LQB1RTWQnDe346O+4eLD6OPD5ubD5eG+4drF7+fH8e3E7uC53sa83Nu62dS93tq939u/3+KnzH2HiydyPzAAfEw4i4U6pbp7rsCgrsWiss2utdW3t9q+wOnSxO/fxO7ZvOLKu93avuDbw+Xlye/wyu7+sdaPj5IrekY2AIxdRoZYRZBmTZJlQpNrR45iOoxiN4xkPIplPY1uRJF1SJN7TpaDVZqMXZ6Va6KcdaelhaGnYpCBNoJNOgCCWjd8VzVlRjFYOy9aOy9aOS1YNyxZNSxbNixeNixhOCxkOSxlOStoOittPSt4RC+FUDiRZVCMXEeFUjvqbqwaUnDRUQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/378e76e1652fe09a31b257c7c45a2b31/7f61c/LarrySummers.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/378e76e1652fe09a31b257c7c45a2b31/da4cb/LarrySummers.webp 416w"],
            "sizes": "(max-width: 416px) 100vw, 416px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/378e76e1652fe09a31b257c7c45a2b31/e17e5/LarrySummers.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/378e76e1652fe09a31b257c7c45a2b31/b0122/LarrySummers.png 416w"],
            "sizes": "(max-width: 416px) 100vw, 416px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/378e76e1652fe09a31b257c7c45a2b31/b0122/LarrySummers.png",
            "alt": "LarrySummers",
            "title": "LarrySummers",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`While I spent most of the 90 minutes respectfully disagreeing with the majority of the panelists (no big deal…it’s not intimidating at all to publicly disagree on the future of cash with the Former Secretary of the Treasury), there was something Mr. Summers said that I couldn’t have agreed with more: “While things take longer to happen than you ever thought they would, when they happen, they happen faster than you ever imagined”.`}</p>
    <p>{`This message could not resonate more on this 26th of March. We’re now just days away from our launch on the DirectExpress program: an exciting milestone whose journey began over two years ago (i.e. longer than we ever though it would). Alternatively, when word leaked that we were launching next week, we had over 1,000 Facebook likes and 20K site visits in just three days. (i.e. faster than we ever imagined)`}</p>
    <p>{`So what does that mean for the future of cash? Well, if PayPerks’ success is inversely related to its persistence, I’m sticking to my guns.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      